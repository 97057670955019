import { MPDialog } from 'theme/ui-kit/dialog';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DialogueEventDialogLayout = _ref => {
  let {
    children,
    ...others
  } = _ref;
  return _jsx(MPDialog, {
    fullWidth: true,
    keepMounted: true,
    maxWidth: "xs",
    externalBuilder: true,
    ...others,
    children: _jsx("div", {
      children: children
    })
  });
};