import { currentUserBalancesSelector, currentUserCSPHeaderBalanceSelector } from 'features/user/current/store/selectors';
import { useSelector } from 'react-redux';
const useCurrentUserHeaderBalances = () => {
  const balances = useSelector(currentUserBalancesSelector);
  const cspBalances = useSelector(currentUserCSPHeaderBalanceSelector);
  const finalBalances = [];
  if (balances) {
    finalBalances.push(balances);
  }
  if (cspBalances) {
    finalBalances.push(cspBalances);
  }
  return finalBalances.length ? finalBalances : null;
};
export default useCurrentUserHeaderBalances;