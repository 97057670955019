import { BenefitTransactionsApi, CommonsApi, CustomersApi } from '@privilege-frontend/api';
import { aspOfferApi } from 'data/api/aspOffer';
import { ESocialPackagesResultType } from 'domain/model';
import { EBalanceType, EOfferActivationSortType, EOfferShortDiscriminator, EOfferType, EOrderSortType } from 'domain/model/enums';
import { pageableCacheClearByTag } from 'presentation/features/general/pageable/cacheStorage/redux/store/slice';
import apiDefinition from '../openApi';
import { getBaseEndpoint, getBenefitTransactionEndpoint, getPageableFromResponseHeaders } from '../openApi/utils';
import { corpOfferApi } from './corpOffer';
import { api, EAspOfferServicesTag, EBookingOfferServicesTag, ECacheServicesTag, ECartServicesTag, ECorpOfferServicesTag, EOrderServicesTag, EProductOfferServicesTag, ETradeOfferServicesTag, EUserServicesTag } from './index';
import { createOfferFavoritePatches, createUserNewActivationPatches, createUserNewActivationTagsToInvalidate } from './patches';
import { productApi } from './productOffer';
import { tradeOfferApi } from './tradeOffer';

/**
 * Модифицированный ответ запроса избранного
 * ! При добавлении новых типов отредактировать также favoritesCountMap
 */

// маппинг для патчей при операциях с избранным с карточек
export const favoritesCountMap = {
  [EOfferType.Trade]: 'tradeOffersCount',
  [EOfferType.Asp]: 'aspOffersCount',
  [EOfferType.Corp]: 'corpOffersCount',
  [EOfferType.Product]: 'productOffersCount',
  [EOfferType.Booking]: 'bookingOffersCount'
};
export const userApi = api.injectEndpoints({
  endpoints: builder => ({
    getCurrentUser: builder.query({
      transformResponse: response => response.data,
      query: () => apiDefinition.user.current({}),
      providesTags: [EUserServicesTag.Current]
    }),
    getUserBalance: builder.query({
      transformResponse: response => {
        return {
          ...response.data,
          type: EBalanceType.Bonuses /*todo asp пока нет АСП - захардкожено*/
        };
      },
      query: () => apiDefinition.user.balance({}),
      providesTags: [EUserServicesTag.Current]
    }),
    getUserBanners: builder.query({
      query: _ref => {
        let {
          place,
          cityId
        } = _ref;
        return apiDefinition.user.banners({
          localityId: cityId,
          place
        });
      },
      transformResponse: response => response.data
    }),
    getUserFavoritesTradeOffers: builder.query({
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: () => apiDefinition.user.favorites({
        offerType: [EOfferType.Trade]
      }),
      providesTags: [ETradeOfferServicesTag.FavoriteList]
    }),
    getUserFavoritesOffers: builder.query({
      transformResponse: response => response.data,
      query: () => apiDefinition.user.favorites({}),
      providesTags: [EUserServicesTag.Favorites]
    }),
    getUserFavorites: builder.query({
      keepUnusedDataFor: 3600,
      transformResponse: response => {
        const transformedData = {
          [EOfferType.Trade]: [],
          [EOfferType.Asp]: [],
          [EOfferType.Corp]: [],
          [EOfferType.Product]: [],
          [EOfferType.Booking]: []
        };
        response.data.forEach(_ref2 => {
          let {
            discriminator,
            id
          } = _ref2;
          // Всё избранное без корректного type будет проигнорировано
          switch (discriminator) {
            case EOfferShortDiscriminator.Trade:
              transformedData[EOfferType.Trade].push(id);
              break;
            case EOfferShortDiscriminator.Corp:
              transformedData[EOfferType.Corp].push(id);
              break;
            case EOfferShortDiscriminator.Booking:
              transformedData[EOfferType.Booking].push(id);
              break;
          }
        });
        const totalCount = Object.values(transformedData).reduce((count, value) => count + value.length, 0);
        return {
          ...transformedData,
          tradeOffersCount: transformedData[EOfferType.Trade].length + transformedData[EOfferType.Asp].length,
          aspOffersCount: transformedData[EOfferType.Asp].length,
          corpOffersCount: transformedData[EOfferType.Corp].length,
          productOffersCount: transformedData[EOfferType.Product].length,
          bookingOffersCount: transformedData[EOfferType.Booking].length,
          totalCount
        };
      },
      query: () => {
        return apiDefinition.user.favorites({});
      },
      providesTags: [EUserServicesTag.FavoritesCount]
    }),
    getUserFavoritesCorpOffers: builder.query({
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: () => apiDefinition.user.favorites({
        offerType: [EOfferType.Corp]
      }),
      providesTags: [ECorpOfferServicesTag.FavoriteList]
    }),
    getUserFavoritesProductOffers: builder.query({
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: () => apiDefinition.user.favorites({
        offerType: [EOfferType.Product]
      }),
      providesTags: [EProductOfferServicesTag.FavoriteList]
    }),
    getUserFavoritesBookingsOffers: builder.query({
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: () => apiDefinition.user.favorites({
        offerType: [EOfferType.Booking]
      }),
      providesTags: [EBookingOfferServicesTag.FavoriteList]
    }),
    getUserOrder: builder.query({
      transformResponse: response => response.data,
      query: id => apiDefinition.user.order.one({
        id
      })
    }),
    getUserOrders: builder.query({
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: _ref3 => {
        let {
          page,
          pageSize,
          statuses,
          offerType
        } = _ref3;
        return apiDefinition.user.order.all({
          statuses,
          page,
          pageSize,
          offerType,
          sort: [EOrderSortType.ByCreatedAt]
        });
      },
      providesTags: [EOrderServicesTag.Orders]
    }),
    getUserOrdersCount: builder.query({
      keepUnusedDataFor: 3600,
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return pageable.totalCount;
      },
      query: () => {
        return apiDefinition.user.order.all({
          page: 1,
          pageSize: 1
        });
      },
      providesTags: [EUserServicesTag.OrdersCount]
    }),
    createUserOrders: builder.mutation({
      transformResponse: response => response.data,
      query: apiDefinition.user.order.create,
      invalidatesTags: [ECartServicesTag.Cart, EOrderServicesTag.Orders, EUserServicesTag.OrdersCount]
    }),
    cancelOrder: builder.mutation({
      transformResponse: response => response.data,
      query: apiDefinition.user.order.cancel,
      invalidatesTags: (result, error, args) => [EOrderServicesTag.Orders, EUserServicesTag.OrdersCount, {
        type: ECacheServicesTag.Common,
        id: args.id
      }],
      async onQueryStarted(args, _ref4) {
        let {
          dispatch,
          queryFulfilled
        } = _ref4;
        await queryFulfilled;
        //очищаем кэш в redux хранилище для pageable
        dispatch(pageableCacheClearByTag({
          tag: EOrderServicesTag.Orders
        }));
      }
    }),
    getUserNewActivation: builder.query({
      transformResponse: response => response.data,
      query: apiDefinition.activation.one,
      async onQueryStarted(args, _ref5) {
        let {
          getState,
          dispatch,
          queryFulfilled
        } = _ref5;
        const {
          data: newActivation
        } = await queryFulfilled;
        //инвалидируем нужные тэги
        const tagsToInvalidate = createUserNewActivationTagsToInvalidate({
          newActivation
        });
        await dispatch(userApi.util.invalidateTags(tagsToInvalidate));

        //патчим данные в кэшах
        await createUserNewActivationPatches({
          newActivation,
          state: getState(),
          dispatch
        });
      }
    }),
    getUserActivations: builder.query({
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: _ref6 => {
        let {
          page,
          pageSize
        } = _ref6;
        return apiDefinition.activation.all({
          page,
          pageSize,
          sort: [EOfferActivationSortType.ByCreatedAt]
        });
      },
      providesTags: [EUserServicesTag.Activations]
    }),
    getUserActivationsCount: builder.query({
      keepUnusedDataFor: 3600,
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return pageable.totalCount;
      },
      query: () => apiDefinition.activation.count({}),
      providesTags: [EUserServicesTag.Activations, EUserServicesTag.ActivationsCount]
    }),
    userFeedback: builder.mutation({
      queryFn: async data => {
        const {
          status
        } = await CommonsApi.userFeedback(getBaseEndpoint(), data);
        return {
          data: status
        };
      }
    }),
    addUserOfferToFavorites: builder.mutation({
      transformResponse: response => response.status,
      query: _ref7 => {
        let {
          id
        } = _ref7;
        return apiDefinition.user.addOfferToFavorites({
          id
        });
      },
      async onQueryStarted(_ref8, _ref9) {
        let {
          id,
          offerType,
          ...payload
        } = _ref8;
        let {
          getState,
          dispatch,
          queryFulfilled
        } = _ref9;
        //патчим данные в кэшах
        const patches = await createOfferFavoritePatches({
          id,
          type: offerType,
          ...payload,
          favorite: true,
          state: getState(),
          dispatch
        });
        try {
          await queryFulfilled;
        } catch {
          patches.forEach(patch => patch.undo());
        }
      }
    }),
    removeUserOfferFromFavorites: builder.mutation({
      transformResponse: response => response.status,
      query: _ref10 => {
        let {
          id
        } = _ref10;
        return apiDefinition.user.removeOfferFromFavorites({
          id
        });
      },
      async onQueryStarted(_ref11, _ref12) {
        let {
          id,
          offerType,
          ...payload
        } = _ref11;
        let {
          getState,
          dispatch,
          queryFulfilled
        } = _ref12;
        //патчим данные в кэшах
        const patches = await createOfferFavoritePatches({
          id,
          type: offerType,
          ...payload,
          favorite: false,
          state: getState(),
          dispatch
        });
        try {
          await queryFulfilled;
        } catch {
          patches.forEach(patch => patch.undo());
        }
      }
    }),
    updateCurrentUser: builder.mutation({
      transformResponse: response => response.status,
      query: _ref13 => {
        let {
          id,
          data
        } = _ref13;
        return apiDefinition.user.update({
          id,
          data
        });
      },
      invalidatesTags: [EUserServicesTag.Current]
    }),
    updateCurrentUserEmail: builder.mutation({
      transformResponse: response => response.data,
      query: _ref14 => {
        let {
          email
        } = _ref14;
        return apiDefinition.user.updateEmail({
          email
        });
      }
    }),
    confirmCode: builder.mutation({
      transformResponse: response => response.status,
      query: _ref15 => {
        let {
          code,
          otpId
        } = _ref15;
        return apiDefinition.user.sendCode({
          code,
          otpId
        });
      }
    }),
    bindCurrentUserCorpRole: builder.mutation({
      transformResponse: response => response.status,
      query: () => apiDefinition.user.assignCorpRole()
    }),
    changeUserLocation: builder.mutation({
      transformResponse: response => response.status,
      query: _ref16 => {
        let {
          userId,
          location
        } = _ref16;
        return apiDefinition.user.changeLocation({
          userId,
          location
        });
      },
      invalidatesTags: [EUserServicesTag.Current, EUserServicesTag.Location, ETradeOfferServicesTag.List, ETradeOfferServicesTag.ByPartnerList, ETradeOfferServicesTag.Details, EAspOfferServicesTag.List, EAspOfferServicesTag.ByPartnerList, EAspOfferServicesTag.Details, ECorpOfferServicesTag.List, ECorpOfferServicesTag.ByPartnerList, ECorpOfferServicesTag.Details, EProductOfferServicesTag.List, EProductOfferServicesTag.ByPartnerList, EProductOfferServicesTag.Details, EBookingOfferServicesTag.List, EBookingOfferServicesTag.Details]
    }),
    resetCurrentUserPassword: builder.mutation({
      transformResponse: response => response.status,
      query: _ref17 => {
        let {
          id
        } = _ref17;
        return apiDefinition.user.resetPassword({
          id
        });
      },
      invalidatesTags: [EUserServicesTag.Current]
    }),
    getUserOfferActivations: builder.query({
      transformResponse: response => response.data,
      query: _ref18 => {
        let {
          id
        } = _ref18;
        return apiDefinition.activation.byOfferId({
          page: 1,
          pageSize: 100000,
          sort: [EOfferActivationSortType.ByCreatedAt],
          offerId: id
        });
      },
      providesTags: [EUserServicesTag.Activations]
    }),
    activateUserOffer: builder.mutation({
      transformResponse: response => response.data,
      query: _ref19 => {
        let {
          id
        } = _ref19;
        return apiDefinition.activation.create({
          offerId: id
        });
      },
      invalidatesTags: [EUserServicesTag.Activations],
      async onQueryStarted(props, _ref20) {
        let {
          dispatch,
          queryFulfilled
        } = _ref20;
        try {
          await queryFulfilled;
          //очищаем кэш в redux хранилище для pageable
          dispatch(pageableCacheClearByTag({
            tag: EUserServicesTag.Activations
          }));
        } catch (e) {
          console.error('Activate offer error', e);
        }
      }
    }),
    getUserOfferAvailability: builder.query({
      transformResponse: response => response.data,
      query: _ref21 => {
        let {
          offerId,
          reasons
        } = _ref21;
        return apiDefinition.user.offerAvailability({
          offerId,
          reasons
        });
      },
      providesTags: [EUserServicesTag.Current, EUserServicesTag.Activations]
    }),
    subscribeToUserOffer: builder.mutation({
      transformResponse: response => response.status,
      query: _ref22 => {
        let {
          offerId,
          subscribe
        } = _ref22;
        return subscribe ? apiDefinition.user.subscribeToOffer({
          offerId
        }) : apiDefinition.user.unSubscribeFromOffer({
          offerId
        });
      },
      async onQueryStarted(_ref23, _ref24) {
        let {
          offerId: id,
          offerType,
          subscribe
        } = _ref23;
        let {
          dispatch,
          queryFulfilled
        } = _ref24;
        await queryFulfilled;
        switch (offerType) {
          case EOfferType.Corp:
            {
              dispatch(corpOfferApi.util.updateQueryData('getCorpOfferDetails', {
                id
              }, draft => {
                Object.assign(draft, {
                  ...draft,
                  subscribe
                });
              }));
              break;
            }
          case EOfferType.Trade:
            {
              dispatch(tradeOfferApi.util.updateQueryData('getTradeOfferDetails', {
                id
              }, draft => {
                Object.assign(draft, {
                  ...draft,
                  subscribe
                });
              }));
              break;
            }
          case EOfferType.Asp:
            {
              dispatch(aspOfferApi.util.updateQueryData('getAspOfferDetails', {
                id
              }, draft => {
                Object.assign(draft, {
                  ...draft,
                  subscribe
                });
              }));
              break;
            }
          case EOfferType.Product:
            {
              dispatch(productApi.util.updateQueryData('getProductOfferDetails', {
                id
              }, draft => {
                Object.assign(draft, {
                  ...draft,
                  subscribe
                });
              }));
              break;
            }
          case EOfferType.Booking:
            {
              // TODO booking
              break;
            }
        }
      }
    }),
    getLandingPage: builder.query({
      query: () => apiDefinition.user.landing.window({}),
      transformResponse: response => response.data
    }),
    getLandingPageTest: builder.query({
      query: () => apiDefinition.user.landing.windowTest({}),
      transformResponse: response => response.data
    }),
    getAllNotifications: builder.query({
      transformResponse: response => response.data,
      query: _ref25 => {
        let {
          userId,
          roles
        } = _ref25;
        return apiDefinition.notification.all({
          userId,
          roles
        });
      }
    }),
    updateNotification: builder.mutation({
      transformResponse: response => response.data,
      query: _ref26 => {
        let {
          id,
          option,
          enabled
        } = _ref26;
        return apiDefinition.notification.update({
          id,
          option,
          enabled
        });
      },
      async onQueryStarted(_ref27, _ref28) {
        let {
          option,
          enabled
        } = _ref27;
        let {
          dispatch,
          queryFulfilled
        } = _ref28;
        const patchResult = dispatch(userApi.util.updateQueryData('getCurrentUser', {}, draft => {
          const notification = draft.notificationSettings?.find(item => item.option?.id === option.id);
          if (notification) Object.assign(notification, {
            enabled
          });
        }));
        queryFulfilled.catch(patchResult.undo);
      }
    }),
    getTransactions: builder.query({
      queryFn: async (params, api) => {
        const response = await BenefitTransactionsApi.getUserBenefitTransactions(getBenefitTransactionEndpoint(), {
          page: params.page - 1,
          size: params.pageSize,
          sort: params.sort
        }, api.signal);
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: {
            data: response.data,
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page
          }
        };
      },
      providesTags: [EUserServicesTag.BTTransactions]
    }),
    getSocialPackagesList: builder.query({
      queryFn: async (params, api) => {
        return await CustomersApi.listCustomerSocialPackagesTyped(getBaseEndpoint(), {
          resultType: ESocialPackagesResultType.List
        }, api.signal);
      },
      providesTags: []
    })
  })
});
export const {
  useGetCurrentUserQuery,
  useGetUserBalanceQuery,
  useGetUserFavoritesOffersQuery,
  useGetUserFavoritesTradeOffersQuery,
  useGetUserFavoritesCorpOffersQuery,
  useGetUserFavoritesProductOffersQuery,
  useGetUserFavoritesBookingsOffersQuery,
  useGetUserFavoritesQuery,
  useGetUserOrdersQuery,
  useGetUserOrdersCountQuery,
  useGetUserOrderQuery,
  useCreateUserOrdersMutation,
  useGetUserNewActivationQuery,
  useGetUserActivationsQuery,
  useGetUserActivationsCountQuery,
  useAddUserOfferToFavoritesMutation,
  useRemoveUserOfferFromFavoritesMutation,
  useUpdateCurrentUserMutation,
  useUpdateCurrentUserEmailMutation,
  useBindCurrentUserCorpRoleMutation,
  useGetUserBannersQuery,
  useActivateUserOfferMutation,
  useGetUserOfferActivationsQuery,
  useGetUserOfferAvailabilityQuery,
  useChangeUserLocationMutation,
  useResetCurrentUserPasswordMutation,
  useSubscribeToUserOfferMutation,
  useUserFeedbackMutation,
  useGetLandingPageQuery,
  useGetTransactionsQuery,
  useGetLandingPageTestQuery,
  useCancelOrderMutation,
  useGetAllNotificationsQuery,
  useUpdateNotificationMutation,
  useConfirmCodeMutation,
  useGetSocialPackagesListQuery
} = userApi;