import { DialogueEventsComponentFlowAdapter } from '../adapters/componentFlow';
import { DialogueEventsComponentSwipeFlowAdapter } from '../adapters/componentSwipeFlow';
import { useDialogueEvents } from '../hooks';
import { DialogueEventsFeatureFlowAdapter } from './featureFlow';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const componentSwipeEnabled = true;
export const DialogueEventsActiveFlowAdapter = () => {
  const {
    activeFlow
  } = useDialogueEvents();
  if (!activeFlow) {
    return null;
  }

  //попробуем составить набор событий на базе единого компонента, если они есть, то рисуем адаптер со swiper-ом для красоты
  if (componentSwipeEnabled) {
    const componentFlow = [];
    for (const e of activeFlow.events) {
      if (!e.component) {
        break;
      }
      componentFlow.push(e);
    }
    if (componentFlow.length > 0 && activeFlow.activeIndex <= componentFlow.length - 1) {
      return _jsx(DialogueEventsComponentSwipeFlowAdapter, {
        activeIndex: activeFlow.activeIndex,
        events: componentFlow
      });
    }
  }

  //иначе рисуем отдельный адаптер исходя из типа
  const event = activeFlow.events?.[activeFlow.activeIndex];
  return event?.feature ? _jsx(DialogueEventsFeatureFlowAdapter, {
    event: event
  }) : _jsx(DialogueEventsComponentFlowAdapter, {
    event: event
  });
};