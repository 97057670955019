import { changeCityEventType } from './types';
import { ChangeCityEventContainer } from './container';
export const createEvent = payload => {
  return {
    required: true,
    type: changeCityEventType,
    component: ChangeCityEventContainer,
    mergeWithNext: false,
    mergeWithPrev: true,
    payload
  };
};