import { UserFinanceBalanceSlotExpireCspAdapter } from 'features/user/details/finances/adapters/balanceSlot/expireCsp';
import { UserFinancesBalanceLayout } from '../layout';
import { UserFinanceBalanceSlotActionsCspAdapter, UserFinanceBalanceSlotTitleAdapter, UserFinanceBalanceSlotValueAdapter } from './balanceSlot';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFinanceBalanceCspAdapter = props => {
  const {
    balance
  } = props;
  return _jsx(UserFinancesBalanceLayout, {
    slots: [_jsx(UserFinanceBalanceSlotExpireCspAdapter, {
      balance: balance,
      disabled: !!balance.disabled
    }, 'expire'), _jsx(UserFinanceBalanceSlotTitleAdapter, {
      balance: balance,
      disabled: !!balance.disabled
    }, 'title'), _jsx(UserFinanceBalanceSlotValueAdapter, {
      balance: balance,
      disabled: !!balance.disabled
    }, 'value'), _jsx(UserFinanceBalanceSlotActionsCspAdapter, {
      onGetInfo: () => {},
      balance: balance
    }, 'actions')]
  });
};