import { confirmCityEventType } from './types';
import { ConfirmCityEventContainer } from './container';
export const createEvent = payload => {
  return {
    required: true,
    type: confirmCityEventType,
    component: ConfirmCityEventContainer,
    mergeWithNext: true,
    mergeWithPrev: false,
    payload
  };
};