import { EBookingOfferListRequestType } from './types';
export const getRequestTypes = requestType => {
  const isAll = requestType === EBookingOfferListRequestType.All;
  const isReset = requestType === EBookingOfferListRequestType.Reset;
  const isServices = requestType === EBookingOfferListRequestType.Services;
  const isPrice = requestType === EBookingOfferListRequestType.Price;
  const isCompensation = requestType === EBookingOfferListRequestType.Compensation;
  return {
    isAll,
    isReset,
    isServices,
    isPrice,
    isCompensation
  };
};
export const getAdjustedPrice = (type, priceRange, requestedPriceRange) => {
  if (!requestedPriceRange || requestedPriceRange[type] === null || priceRange[type] === null) {
    return priceRange[type];
  }
  const equality = type === 'minPrice' ? (requestedPriceRange?.[type] ?? 0) >= (priceRange[type] ?? Infinity) : (requestedPriceRange?.[type] ?? Infinity) <= (priceRange[type] ?? 0);
  return equality ? requestedPriceRange[type] : priceRange[type];
};