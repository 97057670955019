import { UserBalanceValue } from '../../../components/balanceValue';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFinanceBalanceValueAdapter = props => {
  const {
    balance: {
      type,
      balance
    },
    disabled
  } = props;
  return _jsx(UserBalanceValue, {
    variant: "h2",
    type: type,
    value: balance,
    disabled: disabled
  });
};