import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import ScreenLayout from 'presentation/layouts/screen';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { ECatalogUrlParam } from '../../features/catalog/types';
import { EGlobalUrlParam } from '../../features/header/utils';
import BookingOfferListContainer from '../../features/offer/booking/list/container';
import { EBookingUrlParam } from '../../features/offer/booking/types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOffersScreen = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get(EGlobalUrlParam.Search);
  const categoryId = searchParams.get(ECatalogUrlParam.Category) ?? null;
  const minPrice = searchParams.get(EBookingUrlParam.MinPrice) ?? null;
  const maxPrice = searchParams.get(EBookingUrlParam.MaxPrice) ?? null;
  const rzdSocialPackage = searchParams.get(EBookingUrlParam.Compensation) ?? null;
  const searchSort = searchParams.get(EBookingUrlParam.Sort);
  const searchServices = searchParams.get(EBookingUrlParam.Services);
  const [sort, services] = useMemo(() => {
    let foundServices = searchServices !== null ? searchServices.split(',') : undefined;
    if (searchServices === '') {
      foundServices = [];
    }
    return [searchSort ? searchSort.split('+') : undefined, foundServices];
  }, [searchServices, searchSort]);
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(BookingOfferListContainer, {
        guid: guid,
        name: name,
        categoryId: categoryId,
        minPrice: minPrice ? Number(minPrice) : null,
        maxPrice: maxPrice ? Number(maxPrice) : null,
        sort: sort,
        rzdSocialPackage: rzdSocialPackage === 'true',
        services: services
      })
    })
  });
};
export default BookingOffersScreen;