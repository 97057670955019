import { corpOfferReceivedActivationEventType } from './types';
import { CorpOfferReceivedActivationEventContainer } from './container';
export * from './types';
export const createEvent = payload => {
  return {
    required: true,
    type: corpOfferReceivedActivationEventType,
    component: CorpOfferReceivedActivationEventContainer,
    mergeWithNext: false,
    mergeWithPrev: false,
    payload
  };
};