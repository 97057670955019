import { Typography } from '@mui/material';
import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { getUserActivationsRoute } from 'features/user/entry';
import { useHistory } from 'react-router';
import { MPButton } from 'theme/ui-kit/button';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CorpOfferReceivedActivationEventContainer = _ref => {
  let {
    payload: {
      caption,
      content
    },
    onNext
  } = _ref;
  const history = useHistory();
  const openPromotions = () => {
    history.push(getUserActivationsRoute({}));
    onNext?.();
  };
  return _jsx(DialogueEventContentBuilderLayout, {
    title: caption,
    actions: _jsx(MPButton, {
      color: "brand",
      size: "large",
      fullWidth: true,
      onClick: openPromotions,
      children: "\u041F\u043E\u0441\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u0432 \u041C\u043E\u0438\u0445 \u043F\u0440\u0438\u0432\u0438\u043B\u0435\u0433\u0438\u044F\u0445"
    }),
    children: _jsx(Typography, {
      variant: "body2",
      dangerouslySetInnerHTML: {
        __html: content
      }
    })
  });
};