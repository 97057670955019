import { createSelector } from '@reduxjs/toolkit';
import { userApi } from 'api/user';
import { ESocialPackageStatus } from 'domain/model';
import { socialPackagesStableSortPredicate } from 'features/socialPackage/utils';
export const socialPackagesSelector = store => {
  const {
    data: value
  } = userApi.endpoints.getSocialPackagesList.select()(store);
  return value ?? null;
};
export const socialPackagesSortedSelector = createSelector(socialPackagesSelector, sp => {
  if (!sp) {
    return null;
  }
  return [...sp].sort(socialPackagesStableSortPredicate);
});
export const socialPackagesAgreedSelector = createSelector(socialPackagesSortedSelector, sp => {
  if (!sp) {
    return null;
  }
  return sp.filter(spItem => [ESocialPackageStatus.Active, ESocialPackageStatus.Upcoming].some(s => s === spItem.status));
});