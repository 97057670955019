import { Typography } from '@mui/material';
import { EBalanceType } from 'domain/model/enums';
import { BalanceSlotTitle } from '../../layout/controls';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFinanceBalanceSlotTitleAdapter = _ref => {
  let {
    balance,
    disabled = false
  } = _ref;
  return _jsx(BalanceSlotTitle, {
    children: _jsxs(Typography, {
      variant: "body2",
      color: disabled ? 'secondary' : 'inherit',
      children: [balance.type === EBalanceType.Bonuses && 'Бонусный пакет', balance.type === EBalanceType.ASP && 'Авансируемый социальный пакет', balance.type === EBalanceType.CSP && 'Компенсируемый социальный пакет']
    })
  });
};