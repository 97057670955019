import { useGetBookingOfferListQuery } from 'data/api/bookingOffer';
import ErrorHandler from 'data/network/errorHandler';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { ERenderSource } from 'presentation/types';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import OfferListItemContainer from '../../listItem/container';
import { bookingOffersDefaultParams } from '../utils';
import useCategoriesData from './hooks/useCategoriesData';
import { useContextHandlers } from './hooks/useContextHandlers';
import { bookingOfferIsFilterFetchingSelector } from './store/selectors';
import InView from '../../../../components/common/inView';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOfferListData = _ref => {
  let {
    args,
    args: {
      page
    },
    priceRange,
    services,
    guid,
    categoryId,
    onShowCard,
    rzdSocialPackage
  } = _ref;
  const {
    onChangePage
  } = useContextHandlers();
  const {
    isAuthenticated
  } = useAuth();
  const {
    subCategories
  } = useCategoriesData();
  const isFilterFetching = useSelector(bookingOfferIsFilterFetchingSelector);

  // Запрос зависит только от аргументов, дублированных в стейт для корректности работы селекторов
  const {
    data,
    error,
    isSuccess,
    isFetching
  } = useGetBookingOfferListQuery({
    ...args,
    ...(categoryId ? priceRange : bookingOffersDefaultParams.priceRange),
    services: categoryId ? services : [],
    rzdSocialPackage
  }, {
    refetchOnMountOrArgChange: true,
    skip: !!categoryId && isFilterFetching
  });
  const onLoadMore = useCallback(() => {
    onChangePage(page + 1);
  }, [page, onChangePage]);
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  const offers = data?.data;
  const totalCount = data?.totalCount ?? 0;
  const hasOffers = !!offers && offers.length > 0;
  const hasSubCategories = !!subCategories?.length;
  if (!hasOffers) {
    return null;
  }
  const sessionKey = JSON.stringify(args);
  return _jsx(CardPageableList, {
    span: categoryId || hasSubCategories ? 4 : 3,
    lgSpan: categoryId || hasSubCategories ? 6 : 4,
    mdSpan: categoryId || hasSubCategories ? 6 : 4,
    totalCount: totalCount,
    onLoadMore: onLoadMore,
    children: offers.map(offer => _jsx(InView, {
      active: isSuccess && !isFetching,
      sessionKey: sessionKey,
      data: offer,
      onShow: onShowCard,
      children: _jsx(OfferListItemContainer, {
        offer: offer,
        favorite: isAuthenticated && {
          guid
        },
        renderFrom: ERenderSource.OfferList
      }, offer.id)
    }, offer.id))
  });
};
export default BookingOfferListData;