import { paginationSizeVariant } from '../../../../domain/model/constants';
import { EBookingOfferSortType, EOfferClientTagType, EOfferType } from '../../../../domain/model/enums';
import { getOffersListQueryParams } from '../utils';
export const cspOfferTag = {
  type: EOfferClientTagType.CSP,
  name: 'КСП: Отдых'
};
export const bookingOffersDefaultParams = {
  ...getOffersListQueryParams({
    name: null,
    categories: null,
    page: 1,
    pageSize: paginationSizeVariant[0],
    sort: [EBookingOfferSortType.Default]
  }, EOfferType.Booking),
  services: null,
  rzdSocialPackage: false,
  priceRange: {
    minPrice: null,
    maxPrice: null
  }
};
export const getBookingOffersCountQueryParams = props => {
  const {
    name,
    categories
  } = props;
  return {
    search: {
      name,
      categories
    },
    minPrice: null,
    maxPrice: null
  };
};