import useCurrentUserBalances from 'features/user/current/hooks/useBalances';
import { UserFinanceBalanceAdapter } from './balance';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const isDisplayDisabledBalance = true;
export const UserFinanceBalancesAdapter = () => {
  const balancesData = useCurrentUserBalances();
  if (!balancesData) {
    return null;
  }
  const finalBalancesData = isDisplayDisabledBalance ? balancesData : balancesData.filter(item => !item.disabled);
  return finalBalancesData.map((balance, index) => _jsx(UserFinanceBalanceAdapter, {
    balance: balance
  }, index));
};