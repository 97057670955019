import { currentUserBalancesSelector, currentUserCSPBalancesSelector } from 'features/user/current/store/selectors';
import { useSelector } from 'react-redux';
const useCurrentUserBalances = () => {
  /*const { hasFeature } = useTechConfig();*/
  const balances = useSelector(currentUserBalancesSelector);
  const cspBalances = useSelector(currentUserCSPBalancesSelector);
  const finalBalances = [];
  if (balances) {
    finalBalances.push(balances);
  }
  if (cspBalances) {
    finalBalances.push(...cspBalances);
  }
  return finalBalances.length ? finalBalances : null;
};
export default useCurrentUserBalances;