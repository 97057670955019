import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { getUserProfileRoute } from 'features/user/entry';
import { useHistory } from 'react-router';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const NeedFillProfileEventContainer = _ref => {
  let {
    payload: {
      message
    },
    onNext
  } = _ref;
  const history = useHistory();
  const onFill = () => {
    history.push(getUserProfileRoute());
    onNext();
  };
  return _jsx(DialogueEventContentBuilderLayout, {
    title: "\u041D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0437\u0430\u043F\u043E\u043B\u043D\u0438\u0442\u044C \u043F\u0440\u043E\u0444\u0438\u043B\u044C",
    actions: _jsx(ConfirmGroupButtons, {
      children: _jsx(MPButton, {
        fullWidth: true,
        color: "brand",
        size: "large",
        onClick: onFill,
        children: "\u0417\u0430\u043F\u043E\u043B\u043D\u0438\u0442\u044C \u043F\u0440\u043E\u0444\u0438\u043B\u044C"
      })
    }),
    children: message || 'Для получения предложения необходимо заполнить профиль'
  });
};