import useCurrentUserBalances from 'features/user/current/hooks/useBalances';
import { useHistory } from 'react-router';
import { UserMenuItemFinances } from '../../components/menuItems';
import { getUserFinancesRoute } from '../../entry';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserMenuFinancesAdapter = () => {
  const history = useHistory();
  const balances = useCurrentUserBalances();
  const onClick = () => {
    history.push(getUserFinancesRoute({}));
  };
  return balances && balances.length > 0 && _jsx(UserMenuItemFinances, {
    balances: balances,
    onClick: onClick
  });
};