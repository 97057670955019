import { createSelector } from '@reduxjs/toolkit';
import { userApi } from 'data/api/user';
import { EBalanceType, ESocialPackageStatus, EUserStatus } from 'domain/model';
import { socialPackagesAgreedSelector } from 'features/socialPackage/store/selectors';
export const currentUserIdSelector = store => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.id ?? null;
};
export const currentUserLocalityIdSelector = store => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.locality?.id ?? null;
};
export const currentUserBalancesSelector = store => {
  const {
    data: value
  } = userApi.endpoints.getUserBalance.select()(store);
  return value ?? null;
};
export const currentUserIsEmailVerifiedSelector = store => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.emailVerified ?? null;
};
export const currentUserHasRzdSocialPackages = store => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.hasRzdSocialPackage ?? null;
};
export const currentUserIsStatusEnabledSelector = store => {
  const status = userApi.endpoints.getCurrentUser.select({})(store).data?.status;
  return status ? status === EUserStatus.Enabled : null;
};
export const currentUserCSPBalancesSelector = createSelector(socialPackagesAgreedSelector, currentUserHasRzdSocialPackages, (socialPackages, hasRzdSocialPackage) => {
  if (!socialPackages || !hasRzdSocialPackage) {
    return null;
  }
  return socialPackages.map(sp => ({
    type: EBalanceType.CSP,
    disabled: ESocialPackageStatus.Upcoming === sp.status,
    balance: sp.maxLimit,
    date: null,
    pointsExpirationInfo: {
      sum: 0,
      dateExpiration: sp.endDate
    }
  }));
});
export const currentUserCSPHeaderBalanceSelector = createSelector(socialPackagesAgreedSelector, currentUserHasRzdSocialPackages, (socialPackages, hasRzdSocialPackage) => {
  if (!socialPackages || !hasRzdSocialPackage) {
    return null;
  }
  const firstActiveSocialPackage = socialPackages.find(sp => sp.status === ESocialPackageStatus.Active);
  if (!firstActiveSocialPackage) {
    return null;
  }
  return {
    type: EBalanceType.CSP,
    disabled: ESocialPackageStatus.Upcoming === firstActiveSocialPackage.status,
    balance: firstActiveSocialPackage.maxLimit,
    date: null,
    pointsExpirationInfo: {
      sum: 0,
      dateExpiration: firstActiveSocialPackage.endDate
    }
  };
});