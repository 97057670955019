import { useEffect, useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SwipeStepper = _ref => {
  let {
    activeIndex,
    steps
  } = _ref;
  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeIndex);
    }
  }, [activeIndex, swiper]);
  return _jsx(Swiper, {
    slidesPerView: 1,
    allowTouchMove: false,
    noSwiping: true,
    loop: false,
    freeMode: true,
    autoHeight: true,
    onSwiper: setSwiper,
    children: steps.map((step, index) => _jsx(SwiperSlide, {
      children: index <= activeIndex && step
    }, index))
  });
};