import BookingOfferListFilter from 'presentation/features/offer/booking/components/filter';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useSelector } from 'react-redux';
import DataFilterView from '../../../../../../components/common/dataFilter/view';
import BackButton from '../../../../components/back';
import { useContextHandlers } from '../../hooks/useContextHandlers';
import { bookingOfferAllServicesSelector, bookingOfferIsFilterFetchingSelector, bookingOfferListCategoryIdSelector, bookingOfferListFilterDataSelector, bookingOfferListPriceFilterSelector, bookingOfferListRzdSocialPackageSelector, bookingOfferListServicesFilterSelector } from '../../store/selectors';
import { BookingOfferListAsideAdapter } from '../aside';
import { ChipsContainer, SmartPhoneFixedBarWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const BookingOfferListMobileFilterAdapter = _ref => {
  let {
    filterCount,
    onBack
  } = _ref;
  const allServices = useSelector(bookingOfferAllServicesSelector);
  const services = useSelector(bookingOfferListServicesFilterSelector);
  const categoryId = useSelector(bookingOfferListCategoryIdSelector);
  const isFilterFetching = useSelector(bookingOfferIsFilterFetchingSelector);
  const rzdSocialPackage = useSelector(bookingOfferListRzdSocialPackageSelector);
  const {
    priceRange: availableRange,
    services: availableServices
  } = useSelector(bookingOfferListFilterDataSelector);
  const priceFilter = useSelector(bookingOfferListPriceFilterSelector);
  const {
    onSelectService,
    onClearSelectedServices,
    onChangePriceRange,
    onResetFilters,
    onChangeCompensation
  } = useContextHandlers();
  const selectedChips = allServices?.filter(_ref2 => {
    let {
      id
    } = _ref2;
    return id && (!services ? false : services.includes(id));
  });
  return _jsx(SmartPhoneFixedBarWrapper, {
    children: _jsxs(MPGrid, {
      spacing: 2,
      direction: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      width: "100%",
      children: [_jsx(MPGrid, {
        item: true,
        mb: 1,
        children: _jsx(BackButton, {
          label: "\u0424\u0438\u043B\u044C\u0442\u0440",
          onClick: onBack
        })
      }), _jsxs(MPGrid, {
        item: true,
        px: 2,
        children: [!!selectedChips?.length && _jsx(ChipsContainer, {
          children: _jsx(DataFilterView, {
            values: selectedChips,
            onDelete: onSelectService,
            onClear: onClearSelectedServices
          })
        }), _jsx(BookingOfferListAsideAdapter, {
          categoryId: categoryId,
          filterCount: filterCount
        }), _jsx(BookingOfferListFilter, {
          isFilterFetching: isFilterFetching,
          allServices: allServices,
          services: services,
          availableServices: availableServices,
          availableRange: availableRange,
          priceFilter: priceFilter,
          rzdSocialPackage: rzdSocialPackage,
          filterCount: filterCount,
          onChangePriceRange: onChangePriceRange,
          onChangeCompensation: onChangeCompensation,
          onSelectService: onSelectService,
          onResetFilters: onResetFilters
        })]
      })]
    })
  });
};