import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { createEvent } from '../changeCity';
import { confirmCityEventType } from './types';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ConfirmCityEventContainer = _ref => {
  let {
    payload: {
      city
    },
    onNext
  } = _ref;
  const {
    publish
  } = useBusinessEventBus();
  const onChangeCity = () => {
    publish({
      ...createEvent({}),
      hardInsertAfterType: confirmCityEventType
    });
    onNext();
  };
  return _jsx(DialogueEventContentBuilderLayout, {
    title: `Ваше местоположение ${city}?`,
    actions: _jsxs(ConfirmGroupButtons, {
      children: [_jsx(MPButton, {
        fullWidth: true,
        color: "brand",
        size: "large",
        onClick: onNext,
        children: "\u0414\u0430"
      }), _jsx(MPButton, {
        color: "brand",
        variant: "text",
        fullWidth: true,
        size: "large",
        onClick: onChangeCity,
        children: "\u041D\u0435\u0442"
      })]
    })
  });
};