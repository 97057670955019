import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { createEvent } from 'features/user/events/confirmCity';
import ContentLoader from 'presentation/components/common/loader';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddressHelper } from 'utils/address';
import useUserLocalSettings from '../../../hooks/useUserLocalSettings';
import { Wrapper } from './controls';
import { locationDetectorCitySelector } from './store/selectors';
import { locationDetectorCityFetch, locationDetectorClear } from './store/slice';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const LocationDetector = _ref => {
  let {
    children
  } = _ref;
  const dispatch = useDispatch();
  const {
    settings,
    setCity
  } = useUserLocalSettings();
  const {
    publish
  } = useBusinessEventBus();
  const {
    city: localCity
  } = settings;
  const {
    data: city,
    isFetching
  } = useSelector(locationDetectorCitySelector);
  useEffect(() => {
    if (!localCity) {
      const promise = dispatch(locationDetectorCityFetch());
      return () => {
        dispatch(locationDetectorClear());
        promise?.abort();
      };
    }
  }, [dispatch, localCity]);
  useEffect(() => {
    if (city) {
      setCity(city.id, new AddressHelper(city).getLocalitySimpleName());
    }
  }, [city, setCity]);
  useEffect(() => {
    if (city) {
      publish(createEvent({
        city: new AddressHelper(city).getLocalitySimpleName()
      }));
    }
  }, [city, publish]);
  return _jsxs(_Fragment, {
    children: [isFetching && _jsx(Wrapper, {
      children: _jsx(ContentLoader, {})
    }), settings.city && children]
  });
};
export default LocationDetector;