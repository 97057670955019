import UserBalanceExpire from '../../../../components/balanceExpire';
import { BalanceSlotExpire } from '../../layout/controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFinanceBalanceSlotExpireAdapter = _ref => {
  let {
    balance,
    disabled = false
  } = _ref;
  const info = balance.pointsExpirationInfo;
  return info?.dateExpiration && info.sum !== null && info.sum !== 0 && _jsx(BalanceSlotExpire, {
    children: _jsx(UserBalanceExpire, {
      value: info.sum,
      type: balance.type,
      disabled: disabled,
      expireDate: info.dateExpiration
    })
  });
};