import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { useCallback, useEffect, useState } from 'react';
import { eventsFlowEngineFifo } from '../engine';
import { DialogueEventsContext } from './context';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const checkInterval = 3000;
const engine = eventsFlowEngineFifo;
export const DialogueEventsProvider = _ref => {
  let {
    children
  } = _ref;
  const [state, setState] = useState({
    events: [],
    activeFlow: null
  });
  const onNext = useCallback(result => {
    setState(prev => engine.onNext(prev, result));
  }, []);
  const onClose = useCallback(() => {
    setState(prev => engine.onClose(prev));
    onNext();
  }, [onNext]);
  useBusinessEventBus(newEvent => {
    setState(prev => engine.onNew(prev, newEvent));
  });
  useEffect(() => {
    //для того, чтобы не задолбать юзера, добавляем пересчет состояния в интервал
    //если за время предыдущего пересчета накопятся события, то сформируется новый флоу
    const intervalId = setInterval(() => {
      setState(engine.onRecalculate);
    }, checkInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return _jsx(DialogueEventsContext.Provider, {
    value: {
      ...state,
      onNext,
      onClose
    },
    children: children
  });
};