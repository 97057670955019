import { NeedFillProfileEventContainer } from './container';
import { needFillProfileEventType } from './types';
export const createEvent = payload => {
  return {
    required: true,
    type: needFillProfileEventType,
    component: NeedFillProfileEventContainer,
    mergeWithNext: false,
    mergeWithPrev: true,
    payload
  };
};