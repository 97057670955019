import { useDialogueEvents } from '../';
import { DialogueEventDialogLayout } from '../layouts/dialog';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DialogueEventsComponentFlowAdapter = _ref => {
  let {
    event
  } = _ref;
  const {
    onNext,
    onClose
  } = useDialogueEvents();
  const {
    payload,
    component: Component
  } = event ?? {};
  return _jsx(DialogueEventDialogLayout, {
    open: true,
    onClose: onClose,
    disableEscapeKeyDown: event?.required,
    children: Component && _jsx(Component, {
      payload: payload,
      onNext: onNext,
      onClose: onClose
    })
  });
};