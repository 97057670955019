import { MPCheckbox } from 'theme/ui-kit/input';
import { CompensationLabel } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CompensationCheckbox = _ref => {
  let {
    onChange,
    value
  } = _ref;
  return _jsx(MPCheckbox, {
    size: "small",
    color: "brand",
    value: value,
    checked: value,
    label: _jsx(CompensationLabel, {
      children: "\u041A\u043E\u043C\u043F\u0435\u043D\u0441\u0430\u0446\u0438\u044F \u043E\u0442\u0434\u044B\u0445\u0430 (\u041A\u0421\u041F)"
    }),
    onChange: () => onChange(!value)
  });
};
export default CompensationCheckbox;