import { createSelector } from '@reduxjs/toolkit';
import { bookingOfferApi } from 'data/api/bookingOffer';
import { bookingOffersDefaultParams } from '../../utils';
export const bookingOfferListGuidSelector = store => store.bookingOffer.list.guid;
export const bookingOfferListSelector = store => store.bookingOffer.list;
export const bookingOfferListArgsSelector = store => store.bookingOffer.list.data;
export const bookingOfferListIsDefaultServiceSetSelector = store => store.bookingOffer.list.isDefaultServiceSet;
export const bookingOfferListCategoryIdSelector = store => store.bookingOffer.list.categoryId ?? null;
export const bookingOfferListServicesFilterSelector = store => store.bookingOffer.list.filter.services ?? null;
export const bookingOfferListPriceFilterSelector = store => store.bookingOffer.list.filter.priceRange ?? bookingOffersDefaultParams.priceRange;
export const bookingOfferListRzdSocialPackageSelector = store => store.bookingOffer.list.filter.rzdSocialPackage ?? bookingOffersDefaultParams.rzdSocialPackage;
export const bookingOfferListFilterDataSelector = store => store.bookingOffer.list.filterData;
export const bookingOfferIsFilterFetchingSelector = createSelector(bookingOfferListFilterDataSelector, _ref => {
  let {
    isFetching
  } = _ref;
  return isFetching;
});
export const bookingOfferIsFilterErrorSelector = createSelector(bookingOfferListFilterDataSelector, _ref2 => {
  let {
    isFailed
  } = _ref2;
  return isFailed;
});
export const bookingOfferAllServicesSelector = store => store.bookingOffer.list.services;
export const bookingOfferListCountSelector = store => {
  const {
    categoryId,
    data: args,
    filter: {
      priceRange,
      services,
      rzdSocialPackage
    }
  } = store.bookingOffer.list;
  if (!args) {
    return 0;
  }
  const {
    data
  } = bookingOfferApi.endpoints.getBookingOfferList.select({
    ...args,
    ...(categoryId ? priceRange : bookingOffersDefaultParams.priceRange),
    services: categoryId ? services ?? null : [],
    rzdSocialPackage
  })(store);
  return data?.data?.length ?? 0;
};
export const bookingOfferListIsErrorSelector = store => {
  const {
    categoryId,
    data: args,
    filter: {
      priceRange,
      services,
      rzdSocialPackage
    }
  } = store.bookingOffer.list;
  if (!args) {
    return 0;
  }
  const {
    isError
  } = bookingOfferApi.endpoints.getBookingOfferList.select({
    ...args,
    ...(categoryId ? priceRange : bookingOffersDefaultParams.priceRange),
    services: categoryId ? services ?? null : [],
    rzdSocialPackage
  })(store);
  return isError;
};
export const bookingOfferListIsFetchingSelector = store => {
  const {
    categoryId,
    data: args,
    filter: {
      priceRange,
      services,
      rzdSocialPackage
    }
  } = store.bookingOffer.list;
  return args ? bookingOfferApi.endpoints.getBookingOfferList.select({
    ...args,
    ...(categoryId ? priceRange : bookingOffersDefaultParams.priceRange),
    services: categoryId ? services ?? null : [],
    rzdSocialPackage
  })(store).isLoading : false;
};
export const bookingOfferPriceRangeSelector = createSelector(bookingOfferListFilterDataSelector, _ref3 => {
  let {
    priceRange
  } = _ref3;
  return priceRange ?? bookingOffersDefaultParams.priceRange;
});
export const bookingOfferListNoDataSelector = createSelector(bookingOfferListCountSelector, bookingOfferListIsFetchingSelector, bookingOfferIsFilterFetchingSelector, bookingOfferIsFilterErrorSelector, bookingOfferListIsErrorSelector, (count, isListFetching, isFilterFetching, isFilterError, isError) => {
  return !isError && !isFilterError && !isListFetching && !isFilterFetching && count === 0;
});
export const bookingOfferListNoDataNoFilterSelector = createSelector(bookingOfferListCountSelector, bookingOfferListIsFetchingSelector, bookingOfferListIsErrorSelector, (count, isListFetching, isError) => {
  return !isError && !isListFetching && count === 0;
});
export const bookingOfferListIsNewSearchFetchingSelector = createSelector(bookingOfferListIsFetchingSelector, store => store.bookingOffer.list.isNewSearchFetching, (isFetching, isNewSearchFetching) => isNewSearchFetching && isFetching);
export const bookingOfferListNameSelector = store => store.bookingOffer.list.data?.search?.name ?? null;