const warn = function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  return console.warn('EventsFlow [fifo]', ...args);
};

/**
 * обработка очереди событий, реализованная в рамках концепции FIFO
 * @see [about](https://ru.wikipedia.org/wiki/FIFO)
 *
 * @todo в алгоритмах присутствует код, не имеющий отношение к очереди - склейка и вставка в определенное место
 * @todo <i>склейка</i> - код про mergeWithNext и mergeWithPrev, возможно это больше про ui
 * @todo <i>вставка в определенное место</i> - это для другого движка (типа Priority Queue)
 * */
export const eventsFlowEngineFifo = {
  onRecalculate: state => {
    const events = state.events;
    const activeFlow = state.activeFlow;
    if (events.length) {
      if (!activeFlow) {
        //объединяем события по их настройкам
        //как только достигли событие, которое не может быть приклеено к предыдущему - завершаем формирование флоу
        //как только достигли событие, к которому не может быть приклеено следующее - завершаем формирование флоу
        const mergedEvents = [];
        let i;
        for (i = 0; i < events.length; i++) {
          if (!mergedEvents.length) {
            mergedEvents.push(events[i]);
            continue;
          }
          const lastMergedEvent = mergedEvents[mergedEvents.length - 1];
          if (lastMergedEvent.mergeWithNext && events[i].mergeWithPrev) {
            mergedEvents.push(events[i]);
          } else {
            break;
          }
        }

        // запомним оставшиеся события
        const rightEvents = events.slice(i);
        const newFlow = {
          activeIndex: 0,
          events: mergedEvents,
          required: true
        };
        return {
          events: rightEvents,
          activeFlow: newFlow
        };
      } else {
        const newFlow = {
          ...activeFlow,
          events: [...activeFlow.events, ...events]
        };
        return {
          ...state,
          events: [],
          activeFlow: newFlow
        };
      }
    }
    return state;
  },
  onNew: (state, newEvent) => {
    //контролируем уникальность - если неправильный код кинул одно и то же событие несколько раз - учитываем только первое
    const duplicate = state.events.find(event => event.type === newEvent.type) || state.activeFlow?.events?.find(event => event.type === newEvent.type);
    if (duplicate) {
      warn(`duplicated event type '${newEvent.type}' has ignored`);
      return state;
    }
    const events = [...state.events];
    let newActiveFlow = state.activeFlow;
    if (newEvent.hardInsertAfterType) {
      //обработаем вставку в определенное место, после какого-то типа события в активном флоу
      const activeFlowAfterIndex = newActiveFlow?.events?.findIndex(event => event.type === newEvent.hardInsertAfterType) ?? -1;
      if (newActiveFlow && activeFlowAfterIndex !== -1) {
        const activeFlowEvents = [...newActiveFlow.events];
        //вставляем
        activeFlowEvents.splice(activeFlowAfterIndex + 1, 0, newEvent);
        newActiveFlow = {
          ...newActiveFlow,
          events: activeFlowEvents
        };
      } else {
        //обработаем вставку в определенное место, после какого-то типа события из очереди
        const eventsAfterIndex = state.events.findIndex(event => event.type === newEvent.hardInsertAfterType);
        if (eventsAfterIndex !== -1) {
          //вставляем
          events.splice(eventsAfterIndex + 1, 0, newEvent);
        }
      }
    } else {
      //иначе вставляем последним
      events.splice(state.events.length, 0, newEvent);
    }

    //пересчитываем состояние
    return eventsFlowEngineFifo.onRecalculate({
      ...state,
      activeFlow: newActiveFlow,
      events
    });
  },
  onNext: (state, result) => {
    const activeFlow = state.activeFlow;

    //переключаем на следующее событие или завершает текущий флоу
    if (activeFlow) {
      //дёргаем калбэк ивента о выполнении
      const activeEvent = activeFlow.events?.[activeFlow.activeIndex];
      //игнорируем штатный event по currentTarget (чтобы всякие клики на контролы, которые event засылают, не сломали нам onSuccess)
      if (activeEvent?.onSuccess && result !== undefined && !result.currentTarget) {
        activeEvent.onSuccess(result);
      }
      if (activeFlow.activeIndex < activeFlow.events.length - 1) {
        const newFlow = {
          ...activeFlow,
          activeIndex: activeFlow.activeIndex + 1
        };
        return {
          ...state,
          activeFlow: newFlow
        };
      } else {
        return {
          ...state,
          activeFlow: null
        };
      }
    }
    return state;
  },
  onClose: state => {
    /**
     * если для события указан flowId, то при его отмене нужно отменить и все остальные
     * для этого переключаем activeIndex на индекс последнего события с нашим flowId (после чего onNext переключит еще на +1)
     */
    const activeFlow = state.activeFlow;
    if (activeFlow) {
      const activeEvent = activeFlow.events?.[activeFlow.activeIndex];
      if (activeEvent?.flowId) {
        let i;
        for (i = activeFlow.activeIndex; i < activeFlow.events.length; i++) {
          if (activeFlow.events[i].flowId !== activeEvent?.flowId) {
            break;
          }
        }
        const newFlow = {
          ...activeFlow,
          activeIndex: i - 1
        };
        return {
          ...state,
          activeFlow: newFlow
        };
      }
    }
    return state;
  }
};