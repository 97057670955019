import { FormControlLabel } from '@mui/material';
import { StyledCheckbox } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ServiceCheckbox = _ref => {
  let {
    onChange,
    disabled,
    checked,
    label
  } = _ref;
  return _jsx(FormControlLabel, {
    control: _jsx(StyledCheckbox, {
      size: "small",
      color: "brand",
      onChange: onChange,
      disabled: disabled,
      checked: checked
    }),
    label: label
  });
};
export default ServiceCheckbox;