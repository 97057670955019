import { BalanceSlotValue } from '../../layout/controls';
import { UserFinanceBalanceValueAdapter } from '../balanceValue';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFinanceBalanceSlotValueAdapter = _ref => {
  let {
    balance,
    disabled
  } = _ref;
  return _jsx(BalanceSlotValue, {
    children: _jsx(UserFinanceBalanceValueAdapter, {
      balance: balance,
      disabled: disabled
    })
  });
};