import { EOfferStatus } from 'domain/model/enums';
import { useMemo } from 'react';
import { OfferPayButton } from '../../../../components/payButton';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspOfferClaimActivationAdapter = () => {
  const {
    aspOffer,
    isActivationAvailable,
    activation,
    onActivate
  } = useAspOfferClaimContext();
  return useMemo(() => {
    if (isActivationAvailable && aspOffer && (aspOffer?.status === EOfferStatus.Active || aspOffer?.status === EOfferStatus.Upcoming) && !activation.isLastActivationPending) {
      return _jsx(OfferPayButton, {
        disabled: aspOffer?.status === EOfferStatus.Upcoming /* || activation.isUserBalanceNotEnough todo asp пока открыто*/,
        onPay: onActivate
      });
    } else {
      return null;
    }
  }, [isActivationAvailable, aspOffer, activation.isLastActivationPending, onActivate]);
};