import { EBalanceType } from 'domain/model';
import useCurrentUserHeaderBalances from 'features/user/current/hooks/useHeaderBalances';
import { useHistory } from 'react-router';
import { MPGrid } from 'theme/ui-kit/grid';
import { UserBalanceValue } from '../../user/components/balanceValue';
import { getUserFinancesRoute } from '../../user/entry';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BalancesAdapter = () => {
  const history = useHistory();
  const balances = useCurrentUserHeaderBalances();
  const onClick = () => {
    history.push(getUserFinancesRoute({
      type: EBalanceType.Bonuses
    }));
  };
  return balances && balances.length > 0 && _jsx(MPGrid, {
    container: true,
    spacing: 2,
    children: balances.map((balance, index) => _jsx(MPGrid, {
      item: true,
      children: _jsx(UserBalanceValue, {
        type: balance.type,
        value: balance.balance,
        onClick: onClick
      })
    }, index))
  });
};
export default BalancesAdapter;