import axios from 'axios';
import { addAdditionalQueryParams } from './user/bookingOffer';
import { createCancelToken, getBaseEndpoint } from './utils';
/**
 * АПИ фильтров букинга
 */
const booking = {
  serviceCategories: _ref => {
    let {
      signal,
      query,
      categoryId,
      partnerId,
      minPrice,
      maxPrice,
      isRzdSocialPackage
    } = _ref;
    const params = new URLSearchParams();
    addAdditionalQueryParams(params, {
      categories: [categoryId],
      query,
      partnerId
    });
    if (typeof minPrice === 'number') {
      params.append('minPrice', String(minPrice));
    }
    if (typeof maxPrice === 'number') {
      params.append('maxPrice', String(maxPrice));
    }
    if (isRzdSocialPackage) {
      params.append('isRzdSocialPackage', String(isRzdSocialPackage));
    }
    return axios.get(`${getBaseEndpoint()}/customers/current/booking-services/categories`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    });
  },
  priceRange: _ref2 => {
    let {
      signal,
      query,
      categoryId,
      services,
      isRzdSocialPackage
    } = _ref2;
    const params = new URLSearchParams();
    addAdditionalQueryParams(params, {
      categories: [categoryId],
      query
    });
    if (services) {
      services.forEach(item => params.append('serviceCategory', item));
    }
    if (isRzdSocialPackage) {
      params.append('isRzdSocialPackage', String(isRzdSocialPackage));
    }
    return axios.get(`${getBaseEndpoint()}/customers/current/booking-price-range`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    });
  }
};
export default booking;