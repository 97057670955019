import { Typography, useMediaQuery } from '@mui/material';
import AddressCitySingleSelector from 'components/common/address/singleSelector/city';
import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import useUserLocalSettings from 'hooks/useUserLocalSettings';
import { useState } from 'react';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { MPGrid } from 'theme/ui-kit/grid';
import { AddressHelper } from 'utils/address';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ChangeCityEventContainer = _ref => {
  let {
    onNext
  } = _ref;
  const {
    setCity
  } = useUserLocalSettings();
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [city, setLocalCity] = useState(null);
  const onSaveValue = value => {
    setCity(value.id, new AddressHelper(value).getLocalitySimpleName());
  };
  const onChangeLocalCity = address => {
    if (!address) {
      setLocalCity(null);
    } else {
      setLocalCity(address);
      if (isSmDown) {
        onSaveValue(address);
        onNext();
      }
    }
  };
  const onApply = () => {
    if (city) {
      onSaveValue(city);
      onNext();
    }
  };
  const getOptionLabel = address => new AddressHelper(address).getLocalitySimpleName();
  return _jsx(DialogueEventContentBuilderLayout, {
    title: "\u0412\u044B\u0431\u043E\u0440 \u0433\u043E\u0440\u043E\u0434\u0430",
    actions: _jsxs(ConfirmGroupButtons, {
      children: [_jsx(MPButton, {
        fullWidth: true,
        disabled: !city,
        color: "brand",
        size: "large",
        onClick: onApply,
        children: "\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C"
      }), _jsx(MPButton, {
        color: "brand",
        variant: "text",
        fullWidth: true,
        size: "large",
        onClick: onNext,
        children: "\u041E\u0442\u043C\u0435\u043D\u0430"
      })]
    }),
    children: _jsxs(MPGrid, {
      container: true,
      spacing: 2,
      children: [_jsx(MPGrid, {
        item: true,
        zero: 12,
        children: _jsx(Typography, {
          variant: "body2",
          children: "\u0423\u043A\u0430\u0436\u0438\u0442\u0435, \u0433\u0434\u0435 \u0432\u044B \u043D\u0430\u0445\u043E\u0434\u0438\u0442\u0435\u0441\u044C \u0434\u043B\u044F \u043F\u0440\u0430\u0432\u0438\u043B\u044C\u043D\u043E\u0433\u043E \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u044F \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0439"
        })
      }), _jsx(MPGrid, {
        item: true,
        zero: 12,
        children: _jsx(AddressCitySingleSelector, {
          value: city,
          noOptionsText: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u0435",
          label: "\u0413\u043E\u0440\u043E\u0434",
          getOptionLabel: getOptionLabel,
          onChange: onChangeLocalCity
        })
      })]
    })
  });
};