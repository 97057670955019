import { useDialogueEvents } from '../';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DialogueEventsFeatureFlowAdapter = _ref => {
  let {
    event
  } = _ref;
  const {
    onNext,
    onClose
  } = useDialogueEvents();
  if (!event) {
    return null;
  }
  const Feature = event.feature;
  return _jsx(Feature, {
    payload: event.payload,
    onNext: onNext,
    onClose: onClose
  });
};