import UserBalanceExpire from '../../../../components/balanceExpire';
import { BalanceSlotExpire } from '../../layout/controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFinanceBalanceSlotExpireCspAdapter = _ref => {
  let {
    balance,
    disabled = false
  } = _ref;
  return balance.pointsExpirationInfo?.dateExpiration ? _jsx(BalanceSlotExpire, {
    children: _jsx(UserBalanceExpire, {
      value: balance.balance ?? 0,
      type: balance.type,
      disabled: disabled,
      expireDate: balance.pointsExpirationInfo?.dateExpiration
    })
  }) : null;
};